import {
  Divider,
  Drawer as DrawerMui,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import CadastroIcon from '@material-ui/icons/List';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListIcon from '@material-ui/icons/List';
import { usePermission } from '../../hooks/permission';
import { useAuth } from '../../hooks/auth';
import { useStyles } from './styles';

interface ToggleProps {
  handleDrawerToggle: (event: React.MouseEvent<HTMLButtonElement>) => void;
  mobileOpen: boolean;
  pathname: string;
  logoImg: string;
  userName: string;
  moduleName: string;
}

export const AppDrawer: React.FC<ToggleProps> = props => {
  const classes = useStyles();

  const {
    mobileOpen,
    handleDrawerToggle,
    logoImg,
    userName,
    moduleName,
    pathname,
  } = props;

  const { isAuthorized } = usePermission();

  const { signOut } = useAuth();

  const [openCadastroAdministracao, setOpenCadastroAdministracao] =
    React.useState(false);
  const [openSafraAdministracao, setOpenSafraAdministracao] =
    React.useState(false);
  const [openConfiguracaoIndustria, setOpenConfiguracaoIndustria] =
    React.useState(false);
  const [openSafraIndustria, setOpenSafraIndustria] = React.useState(false);
  const [openSafraAssociacao, setOpenSafraAssociacao] = React.useState(false);
  const [openDashboardOrplana, setOpenDashboardOrplana] = React.useState(false);
  const [openSafraOrplana, setOpenSafraOrplana] = React.useState(false);
  const [openDashboardUnica, setOpenDashboardUnica] = React.useState(false);
  const [openSafraUnica, setOpenSafraUnica] = React.useState(false);
  const [openComparativoNIRIndustria, setOpenComparativoNIRIndustria] =
    React.useState(false);
  const [openRelatorioViagemIndustria, setOpenRelatorioViagemIndustria] =
    React.useState(false);
  const [openRelatorioDiarioIndustria, setOpenRelatorioDiarioIndustria] =
    React.useState(false);
  const [openRelatorioQuinzenalIndustria, setOpenRelatorioQuinzenalIndustria] =
    React.useState(false);
  const [
    openRelatorioSistemaatrIndustria,
    setOpenRelatorioSistemaatrIndustria,
  ] = React.useState(false);
  const [openRelatorioGraficoIndustria, setOpenRelatorioGraficoIndustria] =
    React.useState(false);
  const [openRelatorioViagemAssociacao, setOpenRelatorioViagemAssociacao] =
    React.useState(false);
  const [openRelatorioDiarioAssociacao, setOpenRelatorioDiarioAssociacao] =
    React.useState(false);
  const [
    openRelatorioQuinzenalAssociacao,
    setOpenRelatorioQuinzenalAssociacao,
  ] = React.useState(false);
  const [openRelatorioMensalAssociacao, setOpenRelatorioMensalAssociacao] =
    React.useState(false);
  const [
    openRelatorioSistemaatrAssociacao,
    setOpenRelatorioSistemaatrAssociacao,
  ] = React.useState(false);
  const [openRelatorioGraficoAssociacao, setOpenRelatorioGraficoAssociacao] =
    React.useState(false);
  const [openComparativoNIRAssociacao, setOpenComparativoNIRAssociacao] =
    React.useState(false);
  const [openRelatorioProdutorAssociacao, setOpenRelatorioProdutorAssociacao] =
    React.useState(false);
  const [openResumoAssociacao, setOpenResumoAssociacao] = React.useState(false);
  const [openResumoIndustria, setOpenResumoIndustria] = React.useState(false);
  const [openAvaliacaoAssociacao, setOpenAvaliacaoAssociacao] =
    React.useState(false);
  const [openEstratificacaoAssociacao, setOpenEstratificacaoAssociacao] =
    React.useState(false);
  const [openExportacaoAssociacao, setOpenExportacaoAssociacao] =
    React.useState(false);
  const [openRelatoriosQuinzenaisOrplana, setOpenRelatoriosQuinzenaisOrplana] =
    React.useState(false);
  const [openRelatoriosAcumuladosOrplana, setOpenRelatoriosAcumuladosOrplana] =
    React.useState(false);
  const [openEstratificacoesOrplana, setOpenEstratificacoesOrplana] =
    React.useState(false);
  const [openRelatoriosSafraOrplana, setOpenRelatoriosSafraOrplana] =
    React.useState(false);
  const [openRelatoriosQuinzenaisUnica, setOpenRelatoriosQuinzenaisUnica] =
    React.useState(false);
  const [openRelatoriosMensaisUnica, setOpenRelatoriosMensaisUnica] =
    React.useState(false);
  const [openRelatoriosSemanaisUnica, setOpenRelatoriosSemanaisUnica] =
    React.useState(false);

  // Permission of user group

  // Cadastros - Administração
  const cadastroCategoriaAdministracao = isAuthorized(
    'a69d16b8-2def-4b38-bb35-ff868582a0ac',
  );
  const cadastroGrupoUsuarioAdministracao = isAuthorized(
    'd9653aef-4145-440d-ac97-5e3581887bfe',
  );
  const cadastroMenuAdministracao = isAuthorized(
    '71111a40-72d7-44a8-a1b9-f4500fd6bf8d',
  );
  const cadastroSubMenuAdministracao = isAuthorized(
    'bc66dcd1-2e9c-4327-b178-aeaf8eddb788',
  );
  const cadastroGrupoPermissaoAdministracao = isAuthorized('');
  const cadastroOcorrenciaAnaliseAdministracao = isAuthorized(
    '063e0ecb-69b5-4327-9fbb-545010cd2828',
  );
  const cadastroClarificanteAdministracao = isAuthorized(
    'b3b905ce-aa5e-4ce1-9357-366d961dbeee',
  );
  const cadastroRegiaoOrplanaAdministracao = isAuthorized(
    '603ca3bc-e21e-4f0a-8dc7-cf2afa901c12',
  );
  const cadastroRegiaoUnicaAdministracao = isAuthorized(
    '45a9ad81-04cd-4e0b-82d8-b811467a8c1d',
  );
  const cadastroIndustriaAdministracao = isAuthorized(
    '9da47b0d-9f98-4e29-af55-5ee44bb335ff',
  );
  const cadastroAssociacaoAdministracao = isAuthorized(
    '14007dad-6644-479f-9a58-a0034d4918f5',
  );
  const cadastroUsuarioAdministracao = isAuthorized('');

  const cadastrosAdministracao =
    cadastroCategoriaAdministracao ||
    cadastroGrupoPermissaoAdministracao ||
    cadastroGrupoUsuarioAdministracao ||
    cadastroMenuAdministracao ||
    cadastroSubMenuAdministracao ||
    cadastroOcorrenciaAnaliseAdministracao ||
    cadastroClarificanteAdministracao ||
    cadastroRegiaoOrplanaAdministracao ||
    cadastroRegiaoUnicaAdministracao ||
    cadastroIndustriaAdministracao ||
    cadastroAssociacaoAdministracao ||
    cadastroUsuarioAdministracao;

  // Safra - Administração
  const safraCadastroAdministracao = isAuthorized(
    '7ccd0c11-a3b6-4f3c-a5ba-f586d201d36c',
  );
  const safraFormulaAdministracao = isAuthorized(
    'bcd8f870-fc75-43c7-8c79-a11b20cd93c6',
  );

  const safraAdministracao =
    safraCadastroAdministracao || safraFormulaAdministracao;

  // Configuração - Industrial
  const configuracaoDadosCadastraisIndustria = isAuthorized('');
  const configuracaoTokenIndustria = isAuthorized(
    '10bfd3be-ec3a-4a5a-9e32-17ba8020641e',
  );

  const configuracaoIndustria =
    configuracaoDadosCadastraisIndustria || configuracaoTokenIndustria;

  // Safra - Industrial
  const safraCadastroIndustria = isAuthorized(
    '9b3ce0d4-ce03-4b65-982f-1731b2a9bb52',
  );
  const safraLimitesIndustria = isAuthorized(
    '13e05022-43ca-44f5-9a47-dafe19853491',
  );
  const safraAtualizacaoIndustria = isAuthorized(
    '718009ce-9fc7-461c-8261-cedf693cb83c',
  );

  const safraIndustria =
    safraCadastroIndustria ||
    safraLimitesIndustria ||
    safraAtualizacaoIndustria;

  // Safra - Associacao
  const safraAtualizacaoIndustriaAssociacao = isAuthorized(
    '8b3a093a-9d8f-40cf-bf9c-a0480d6c5258',
  );

  const safraAssociacao = safraAtualizacaoIndustriaAssociacao;

  // Safra - Orplana
  const safraAtualizacaoIndustriaOrplana = isAuthorized(
    '31940adf-6f8c-4e68-b161-ed11448b4ecb',
  );

  const safraOrplana = safraAtualizacaoIndustriaOrplana;

  // Safra - Unica
  const safraAtualizacaoIndustriaUnica = isAuthorized(
    '3c299a9a-8293-483c-82ba-9c2e1063e441',
  );

  const safraUnica = safraAtualizacaoIndustriaUnica;

  // Comparativo NIR - Industrial
  const comparativoNIREquipamentoIndustria = isAuthorized(
    'bafff43b-9ac7-4126-9cea-7d665c7e73d9',
  );
  const comparativoNIRCadastroIndustria = isAuthorized(
    '5e7983ef-1e41-4d44-a7e5-78b65aefdd4e',
  );

  const comparativoNIRIndustria =
    comparativoNIREquipamentoIndustria || comparativoNIRCadastroIndustria;

  // Comparativo NIR - Associacao
  const comparativoNIRCadastroAssociacao = isAuthorized(
    'c8dea7d3-eae9-428c-adcd-1a5e87c3cda3',
  );

  const comparativoNIRAssociacao = comparativoNIRCadastroAssociacao;

  // Exportacao - Associacao
  const exportacaoDiarioAssociacao = isAuthorized(
    '62a6c29a-a86f-4972-86eb-f8a3ae1a93a4',
  );
  const exportacaoResumoDiarioAssociacao = isAuthorized(
    '4f75074f-6a9f-4b17-9f2a-275e1081de54',
  );
  const exportacaoQuinzenalAssociacao = isAuthorized(
    '406c82e3-85c1-4034-a6f9-36944f60eb53',
  );
  const exportacaoMensalAssociacao = isAuthorized(
    '905b2d7c-a392-499f-b916-abefa8058533',
  );
  const exportacaoFatorQualidadeAssociacao = isAuthorized(
    '2726530a-af2e-4738-b2ae-76534d26548a',
  );

  const exportacaoAssociacao =
    exportacaoDiarioAssociacao ||
    exportacaoResumoDiarioAssociacao ||
    exportacaoQuinzenalAssociacao ||
    exportacaoMensalAssociacao ||
    exportacaoFatorQualidadeAssociacao;

  // Relatório Viagem - Industrial
  const relatorioViagemViagemIndustria = isAuthorized(
    '40ca7f8e-cf50-4c6e-9ef7-a1d844135b5d',
  );

  const relatorioViagemIndustria = relatorioViagemViagemIndustria;

  // Relatório Diário - Industrial
  const relatorioDiarioDiarioIndustria = isAuthorized(
    '0750b249-b106-42f7-a575-14c480ec4cfa',
  );
  const relatorioDiarioGeralIndustria = isAuthorized(
    'dfdae672-2eab-4bd1-8b41-65cc84d5f79f',
  );
  const relatorioDiarioTipoIndustria = isAuthorized(
    'b221e8a0-5fd6-4e71-b9e8-1789793207a1',
  );
  const relatorioDiarioEntregaIndustria = isAuthorized(
    '24130fb2-96ec-458f-9dcb-69e8eb36c070',
  );

  const relatorioDiarioIndustria =
    relatorioDiarioEntregaIndustria ||
    relatorioDiarioTipoIndustria ||
    relatorioDiarioDiarioIndustria ||
    relatorioDiarioGeralIndustria;

  // Relatório Quinzenal - Industrial
  const relatorioQuinzenalQuinzenalIndustria = isAuthorized(
    '6df6dd3a-435b-4f12-8151-36036012fedc',
  );
  const relatorioQuinzenalFatorQualidadeIndustria = isAuthorized(
    '56c557fa-b122-48ef-be1f-d66c626785a8',
  );

  const relatorioQuinzenalIndustria =
    relatorioQuinzenalQuinzenalIndustria ||
    relatorioQuinzenalFatorQualidadeIndustria;

  // Relatório Quinzenal Sistema ATR - Industrial
  const relatorioQuinzenalSistemaatrIndustria = isAuthorized(
    '1ca4f39a-e501-44db-aa91-c4103197f3ad',
  );
  const relatorioQuinzenalSistemaatrQuqIndustria = isAuthorized(
    '6decd895-3316-4e98-899b-e4938d51ca81',
  );
  const relatorioQuinzenalSistemaatrATRuqIndustria = isAuthorized(
    '3200af37-41f5-47c0-8e96-0b425570ced9',
  );

  const relatorioSistemaatrIndustria =
    relatorioQuinzenalSistemaatrATRuqIndustria ||
    relatorioQuinzenalSistemaatrQuqIndustria ||
    relatorioQuinzenalSistemaatrIndustria;

  // Gráfico - Industrial
  const relatorioQuinzenalGraficoIndustria = isAuthorized(
    '5092bb21-16ac-4bea-9abb-ba193b92c928',
  );

  const relatorioGraficoIndustria = relatorioQuinzenalGraficoIndustria;

  // Resumos - Industria
  const resumoTabelaEntregaIndustria = isAuthorized(
    '2abaa88e-f6de-47ca-8c64-1c06b0356817',
  );

  const resumoIndustria = resumoTabelaEntregaIndustria;

  // Relatório Viagem - Associação
  const relatorioViagemViagemAssociacao = isAuthorized(
    'ebf7b8b5-d26b-46a0-a842-b69734675a71',
  );

  const relatorioViagemAssociacao = relatorioViagemViagemAssociacao;

  // Relatório Diário - Associação
  const relatorioDiarioDiarioAssociacao = isAuthorized(
    '618d2d38-85de-4326-9302-5a30a863c673',
  );

  const relatorioDiarioAssociacao = relatorioDiarioDiarioAssociacao;

  // Relatório Quinzenal - Associação
  const relatorioQuinzenalQuinzenalAssociacao = isAuthorized(
    '98a3d42f-9d04-4af5-a359-465ec01e42d7',
  );
  const relatorioQuinzenalFatorQualidadeAssociacao = isAuthorized(
    '4d096d89-fadc-4029-bdef-8f7b6e35af3f',
  );
  const relatorioQuinzenalGeralAssociacao = isAuthorized(
    '11a8ede4-8dd9-478b-9c0e-1f6330bff3a6',
  );

  const relatorioQuinzenalAssociacao =
    relatorioQuinzenalQuinzenalAssociacao ||
    relatorioQuinzenalFatorQualidadeAssociacao ||
    relatorioQuinzenalGeralAssociacao;

  // Relatório - Associação
  const relatorioQuinzenalSistemaatrAssociacao = isAuthorized(
    '165011bd-b17a-43ab-a878-546138d3cfc2',
  );
  const relatorioQuinzenalSistemaatrFatorQualidadeAssociacao = isAuthorized(
    '412c1e1e-5ed0-4223-999d-03698cb59e37',
  );
  const relatorioQuinzenalSistemaatrQuqAssociacao = isAuthorized(
    '382dde55-5678-48f9-898f-a2007db06340',
  );
  const relatorioQuinzenalSistemaatrATRuqAssociacao = isAuthorized(
    'cd2bacbb-6df8-4f2b-99bc-36a7b17d00f6',
  );

  const relatorioSistemaatrAssociacao =
    relatorioQuinzenalSistemaatrAssociacao ||
    relatorioQuinzenalSistemaatrQuqAssociacao ||
    relatorioQuinzenalSistemaatrATRuqAssociacao ||
    relatorioQuinzenalSistemaatrFatorQualidadeAssociacao;

  // Gráfico - Associação
  const relatorioQuinzenalGraficoAssociacao = isAuthorized(
    '097302e8-87e5-4e49-9061-37d3cd80632f',
  );

  const relatorioGraficoAssociacao = relatorioQuinzenalGraficoAssociacao;

  // Relatorio Produtores - Associação
  const relatorioProdutorPagamentoAssociacao = isAuthorized(
    '923806ad-b7bf-45b9-8cf4-b70f1d8e3136',
  );

  const relatorioProdutorAssociacao = relatorioProdutorPagamentoAssociacao;

  // Resumos - Associação
  const resumoIndustriaAssociacao = isAuthorized(
    '50df0dab-18df-4e7a-ae29-ba472ddacd3c',
  );
  const resumoAcumuladoAssociacao = isAuthorized(
    '0c432893-aceb-4920-ab84-c20b04868e80',
  );
  const resumoTabelaEntregaAssociacao = isAuthorized(
    '714e9e30-b6f6-42b1-a87b-eb6139d22cad',
  );

  const resumoAssociacao =
    resumoIndustriaAssociacao ||
    resumoAcumuladoAssociacao ||
    resumoTabelaEntregaAssociacao;

  // Avaliação - Associação
  const avaliacaoAmostragemDiaAssociacao = isAuthorized(
    'ac2080e9-e1a8-42b1-8740-363302d1a75b',
  );
  const avaliacaoAmostragemSafraAssociacao = isAuthorized(
    '06169475-d868-4b21-adb0-0b4a2e3679f1',
  );
  const avaliacaoClarificanteIndustriaAssociacao = isAuthorized(
    '957a0a0e-d32e-4fb7-9f6b-431c525c0eb2',
  );
  const avaliacaoAmostragemIntervaloAssociacao = isAuthorized(
    '6698975c-0e7f-4123-91af-07c4455bc4f4',
  );
  const avaliacaoAmostragemIntervaloCargaAssociacao = isAuthorized(
    '7efe98e1-be62-402d-81bf-c2084c3d0fac',
  );

  const avaliacaoAssociacao =
    avaliacaoAmostragemDiaAssociacao ||
    avaliacaoAmostragemSafraAssociacao ||
    avaliacaoClarificanteIndustriaAssociacao ||
    avaliacaoAmostragemIntervaloAssociacao ||
    avaliacaoAmostragemIntervaloCargaAssociacao;

  // Estratificação - Associação
  const estratificacaoCanaAssociacao = isAuthorized(
    '51be05fa-bc5d-40dd-a39c-69056d8a8cd5',
  );
  const estratificacaoATRAssociacao = isAuthorized(
    '6bfeadd4-0a94-4958-bec9-030a328b263e',
  );
  const estratificacaoFatorQualidadeAssociacao = isAuthorized(
    '2ccac5d4-50f0-465b-9c78-1cf1de019826',
  );

  const estratificacaoAssociacao =
    estratificacaoCanaAssociacao ||
    estratificacaoATRAssociacao ||
    estratificacaoFatorQualidadeAssociacao;

  const relatorioMensalRecolhimentoTaxaAssociacao = isAuthorized(
    'cda6a134-7430-474f-8458-da9f5666a357',
  );
  const relatorioMensalAssociadosAssociacao = isAuthorized(
    'cda6a134-7430-474f-8458-da9f5666a357',
  );

  const relatorioMensalAssociacao =
    relatorioMensalRecolhimentoTaxaAssociacao ||
    relatorioMensalAssociadosAssociacao;

  // Dashboards Orplana
  const dashboardOrplanaIndustrias = isAuthorized(
    '63f8b346-cf17-468e-9d45-6e169c382a6a',
  );

  const dashboardOrplana = dashboardOrplanaIndustrias;

  // Relatorios Quinzenais - Orplana
  const quinzenalAssociacaoRelatq2Orplana = isAuthorized(
    '63f8b346-cf17-468e-9d45-6e169c382a6a',
  );
  const quinzenalEstadoRelatq5Orplana = isAuthorized(
    '63f8b346-cf17-468e-9d45-6e169c382a6a',
  );

  const quinzenalEstadoRelatq50Orplana = isAuthorized(
    '63f8b346-cf17-468e-9d45-6e169c382a6a',
  );

  const quinzenalIndustriaRelatq21Orplana = isAuthorized(
    '63f8b346-cf17-468e-9d45-6e169c382a6a',
  );

  const quinzenalIndustriaRelatq28Orplana = isAuthorized(
    '63f8b346-cf17-468e-9d45-6e169c382a6a',
  );

  const quinzenalOrplana =
    quinzenalAssociacaoRelatq2Orplana ||
    quinzenalEstadoRelatq5Orplana ||
    quinzenalEstadoRelatq50Orplana ||
    quinzenalIndustriaRelatq21Orplana ||
    quinzenalIndustriaRelatq28Orplana;

  // Relatorios Acumulados - Orplana
  const acumuladoAssociacaoRelata2Orplana = isAuthorized(
    '55721f9e-71b5-4061-a80d-cbb4acf4cde5',
  );
  const acumuladoRegiaoRelata42Orplana = isAuthorized(
    '55721f9e-71b5-4061-a80d-cbb4acf4cde5',
  );
  const acumuladoEstadoRelata46Orplana = isAuthorized(
    '55721f9e-71b5-4061-a80d-cbb4acf4cde5',
  );
  const acumuladoEstadoRelata70Orplana = isAuthorized(
    '55721f9e-71b5-4061-a80d-cbb4acf4cde5',
  );

  const acumuladoOrplana =
    acumuladoAssociacaoRelata2Orplana ||
    acumuladoRegiaoRelata42Orplana ||
    acumuladoEstadoRelata46Orplana ||
    acumuladoEstadoRelata70Orplana;

  // Relatorios Safra - Orplana
  const safraAtualizacaoOrplana = isAuthorized(
    '0f2406cb-9108-46a9-9228-ed8397477614',
  );

  const safraRelatorioOrplana = safraAtualizacaoOrplana;

  // Relatorios Estratificação - Orplana
  const estratoRelata44Orplana = isAuthorized(
    '4ea55a5e-0d15-4d31-b3b1-ba5239287273',
  );
  const estratoRelata45Orplana = isAuthorized(
    '4ea55a5e-0d15-4d31-b3b1-ba5239287273',
  );

  const estratificacaoOrplana =
    estratoRelata44Orplana || estratoRelata45Orplana;

  // Dashboards Unica
  const dashboardUnicaIndustrias = isAuthorized(
    'c6a40083-5ca7-4a88-a7f3-b0f1c7f4d444',
  );

  const dashboardUnica = dashboardUnicaIndustrias;

  // Relatorios Quinzenais - Unica
  const quinzenalEstadoRelatq5Unica = isAuthorized(
    '98c7149d-10ef-4742-a33a-59bc0f6e060c',
  );

  const quinzenalEstadoRelatq50Unica = isAuthorized(
    '98c7149d-10ef-4742-a33a-59bc0f6e060c',
  );

  const quinzenalIndustriaUnica = isAuthorized(
    '98c7149d-10ef-4742-a33a-59bc0f6e060c',
  );

  const quinzenalUnica =
    quinzenalEstadoRelatq5Unica ||
    quinzenalEstadoRelatq50Unica ||
    quinzenalIndustriaUnica;

  // Relatorios Mensais - Unica
  const mensalIndustriaUnica = isAuthorized(
    'cf628bb0-0cb5-4bf7-9462-89b96ffb2164',
  );

  const mensalUnica = mensalIndustriaUnica;

  // Relatorios Semanais - Unica
  const semanalDetalhadoUnica = isAuthorized(
    'c6a40083-5ca7-4a88-a7f3-b0f1c7f4d444',
  );

  const semanalUnica = semanalDetalhadoUnica;

  const handleListOpenClick = (folder: string): void => {
    switch (folder) {
      case 'cadastro_administracao':
        setOpenCadastroAdministracao(!openCadastroAdministracao);
        break;
      case 'safra_administracao':
        setOpenSafraAdministracao(!openSafraAdministracao);
        break;
      case 'safra_associacao':
        setOpenSafraAssociacao(!openSafraAssociacao);
        break;
      case 'safra_orplana':
        setOpenSafraOrplana(!openSafraOrplana);
        break;
      case 'dashboard_orplana':
        setOpenDashboardOrplana(!openDashboardOrplana);
        break;
      case 'safra_unica':
        setOpenSafraUnica(!openSafraUnica);
        break;
      case 'dashboard_unica':
        setOpenDashboardUnica(!openDashboardUnica);
        break;
      case 'configuracao_industria':
        setOpenConfiguracaoIndustria(!openConfiguracaoIndustria);
        break;
      case 'safra_industria':
        setOpenSafraIndustria(!openSafraIndustria);
        break;
      case 'comparativo_nir_industria':
        setOpenComparativoNIRIndustria(!openComparativoNIRIndustria);
        break;
      case 'relatorio_viagem_industria':
        setOpenRelatorioViagemIndustria(!openRelatorioViagemIndustria);
        break;
      case 'relatorio_diario_industria':
        setOpenRelatorioDiarioIndustria(!openRelatorioDiarioIndustria);
        break;
      case 'relatorio_quinzenal_industria':
        setOpenRelatorioQuinzenalIndustria(!openRelatorioQuinzenalIndustria);
        break;
      case 'relatorio_sistemaatr_industria':
        setOpenRelatorioSistemaatrIndustria(!openRelatorioSistemaatrIndustria);
        break;
      case 'relatorio_grafico_industria':
        setOpenRelatorioGraficoIndustria(!openRelatorioGraficoIndustria);
        break;
      case 'relatorio_viagem_associacao':
        setOpenRelatorioViagemAssociacao(!openRelatorioViagemAssociacao);
        break;
      case 'relatorio_diario_associacao':
        setOpenRelatorioDiarioAssociacao(!openRelatorioDiarioAssociacao);
        break;
      case 'relatorio_quinzenal_associacao':
        setOpenRelatorioQuinzenalAssociacao(!openRelatorioQuinzenalAssociacao);
        break;
      case 'relatorio_sistemaatr_associacao':
        setOpenRelatorioSistemaatrAssociacao(
          !openRelatorioSistemaatrAssociacao,
        );
        break;
      case 'relatorio_mensal_associacao':
        setOpenRelatorioMensalAssociacao(!openRelatorioMensalAssociacao);
        break;
      case 'relatorio_grafico_associacao':
        setOpenRelatorioGraficoAssociacao(!openRelatorioGraficoAssociacao);
        break;
      case 'relatorio_produtor_associacao':
        setOpenRelatorioProdutorAssociacao(!openRelatorioProdutorAssociacao);
        break;
      case 'resumo_associacao':
        setOpenResumoAssociacao(!openResumoAssociacao);
        break;
      case 'resumo_industria':
        setOpenResumoIndustria(!openResumoIndustria);
        break;
      case 'avaliacao_associacao':
        setOpenAvaliacaoAssociacao(!openAvaliacaoAssociacao);
        break;
      case 'estratificacao_associacao':
        setOpenEstratificacaoAssociacao(!openEstratificacaoAssociacao);
        break;
      case 'comparativo_nir_associacao':
        setOpenComparativoNIRAssociacao(!openComparativoNIRAssociacao);
        break;
      case 'exportacao_associacao':
        setOpenExportacaoAssociacao(!openExportacaoAssociacao);
        break;
      case 'quinzenais_orplana':
        setOpenRelatoriosQuinzenaisOrplana(!openRelatoriosQuinzenaisOrplana);
        break;
      case 'acumulados_orplana':
        setOpenRelatoriosAcumuladosOrplana(!openRelatoriosAcumuladosOrplana);
        break;
      case 'estratificacao_orplana':
        setOpenEstratificacoesOrplana(!openEstratificacoesOrplana);
        break;
      case 'relatorio_safra_orplana':
        setOpenRelatoriosSafraOrplana(!openRelatoriosSafraOrplana);
        break;
      case 'semanais_unica':
        setOpenRelatoriosSemanaisUnica(!openRelatoriosSemanaisUnica);
        break;
      case 'quinzenais_unica':
        setOpenRelatoriosQuinzenaisUnica(!openRelatoriosQuinzenaisUnica);
        break;
      case 'mensais_unica':
        setOpenRelatoriosMensaisUnica(!openRelatoriosMensaisUnica);
        break;
      default:
        break;
    }
  };

  const drawer = (
    <div>
      <div className={classes.drawerHeader}>
        <img src={logoImg} alt="Sistema ATR" height="50px" />
        <div className={classes.userHeader}>
          <Typography variant="subtitle2" color="inherit" noWrap>
            <Link
              to="/perfil"
              style={{ textDecoration: 'none', color: 'green' }}
            >
              {userName}
            </Link>
          </Typography>
          <Typography variant="subtitle2" color="inherit" noWrap>
            Seja bem vindo
          </Typography>
        </div>
      </div>
      <Typography
        variant="subtitle2"
        color="inherit"
        noWrap
        style={{ paddingLeft: '10px', paddingBottom: '10px' }}
      >
        {moduleName.replace('MÓDULO ', '')}
      </Typography>
      <Divider />
      <List>
        <ListItem
          button
          component={Link}
          to="/dashboard"
          selected={pathname === '/dashboard'}
        >
          <ListItemText primary="Dashboard" />
        </ListItem>
      </List>
      <Divider />
      {cadastrosAdministracao && (
        <ListItem
          button
          onClick={() => handleListOpenClick('cadastro_administracao')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Cadastros" />
          {openCadastroAdministracao ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openCadastroAdministracao} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {cadastroCategoriaAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/categoria"
              selected={pathname === '/categoria'}
            >
              <ListItemText primary="Categoria" />
            </ListItem>
          )}
          {cadastroGrupoUsuarioAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/grupo-usuario"
              selected={pathname === '/grupo-usuario'}
            >
              <ListItemText primary="Grupo de Usuário" />
            </ListItem>
          )}
          {cadastroMenuAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/menu"
              selected={pathname === '/menu'}
            >
              <ListItemText primary="Menu" />
            </ListItem>
          )}
          {cadastroSubMenuAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/sub-menu"
              selected={pathname === '/sub-menu'}
            >
              <ListItemText primary="Sub Menu" />
            </ListItem>
          )}
          {cadastroGrupoPermissaoAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/grupo-permissao"
              selected={pathname === '/grupo-permissao'}
            >
              <ListItemText primary="Grupo Permissão" />
            </ListItem>
          )}
          {cadastroClarificanteAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/clarificante"
              selected={pathname === '/clarificante'}
            >
              <ListItemText primary="Clarificante" />
            </ListItem>
          )}
          {cadastroOcorrenciaAnaliseAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/ocorrencia-analise"
              selected={pathname === '/ocorrencia-analise'}
            >
              <ListItemText primary="Ocorrência Análise" />
            </ListItem>
          )}
          {cadastroRegiaoOrplanaAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/regiao-orplana"
              selected={pathname === '/regiao-orplana'}
            >
              <ListItemText primary="Região ORPLANA" />
            </ListItem>
          )}
          {cadastroRegiaoUnicaAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/regiao-unica"
              selected={pathname === '/regiao-unica'}
            >
              <ListItemText primary="Região UNICA" />
            </ListItem>
          )}
          {cadastroAssociacaoAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/associacao"
              selected={pathname === '/associacao'}
            >
              <ListItemText primary="Associação" />
            </ListItem>
          )}
          {cadastroIndustriaAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/industria"
              selected={pathname === '/industria'}
            >
              <ListItemText primary="Unidade Industrial" />
            </ListItem>
          )}
          {cadastroUsuarioAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/usuario"
              selected={pathname === '/usuario'}
            >
              <ListItemText primary="Usuário" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {dashboardOrplana && (
        <ListItem
          button
          onClick={() => handleListOpenClick('dashboard_orplana')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboards" />
          {openDashboardOrplana ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openDashboardOrplana} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {dashboardOrplanaIndustrias && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/dash-orplana-industrias"
              selected={pathname === '/dash-orplana-industrias'}
            >
              <ListItemText primary="Dashboard UIs" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {dashboardUnica && (
        <ListItem button onClick={() => handleListOpenClick('dashboard_unica')}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboards" />
          {openDashboardUnica ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openDashboardUnica} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {dashboardUnicaIndustrias && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/dash-unica-industrias"
              selected={pathname === '/dash-unica-industrias'}
            >
              <ListItemText primary="Dashboard UIs" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {safraAdministracao && (
        <ListItem
          button
          onClick={() => handleListOpenClick('safra_administracao')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Safra" />
          {openSafraAdministracao ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openSafraAdministracao} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {safraCadastroAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/safra"
              selected={pathname === '/safra'}
            >
              <ListItemText primary="Cadastro" />
            </ListItem>
          )}
          {safraFormulaAdministracao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/formula"
              selected={pathname === '/formula'}
            >
              <ListItemText primary="Perfil de Fórmula" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {configuracaoIndustria && (
        <ListItem
          button
          onClick={() => handleListOpenClick('configuracao_industria')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Configurações" />
          {openConfiguracaoIndustria ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openConfiguracaoIndustria} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {configuracaoDadosCadastraisIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/industria-dados"
              selected={pathname === '/industria-dados'}
            >
              <ListItemText primary="Dados Cadastrais" />
            </ListItem>
          )}
          {configuracaoTokenIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/industria-token"
              selected={pathname === '/industria-token'}
            >
              <ListItemText primary="Token" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {safraIndustria && (
        <ListItem button onClick={() => handleListOpenClick('safra_industria')}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Safra" />
          {openSafraIndustria ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openSafraIndustria} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {safraCadastroIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/safra-industria"
              selected={pathname === '/safra-industria'}
            >
              <ListItemText primary="Cadastro" />
            </ListItem>
          )}
          {safraLimitesIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/industria-limite"
              selected={pathname === '/industria-limite'}
            >
              <ListItemText primary="Limites Mínimos e Máximos" />
            </ListItem>
          )}
          {safraAtualizacaoIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/atualizacao-industria"
              selected={pathname === '/atualizacao-industria'}
            >
              <ListItemText primary="Atualizações" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {comparativoNIRIndustria && (
        <ListItem
          button
          onClick={() => handleListOpenClick('comparativo_nir_industria')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Comparativo NIR" />
          {openComparativoNIRIndustria ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openComparativoNIRIndustria} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {comparativoNIREquipamentoIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/equipamento-nir"
              selected={pathname === '/equipamento-nir'}
            >
              <ListItemText primary="Equipamentos NIR - Cadastro" />
            </ListItem>
          )}
          {comparativoNIRCadastroIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/comparativo-nir-industria"
              selected={pathname === '/comparativo-nir-industria'}
            >
              <ListItemText primary="Cadastro Comparativos" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {comparativoNIRAssociacao && (
        <ListItem
          button
          onClick={() => handleListOpenClick('comparativo_nir_associacao')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Comparativo NIR" />
          {openComparativoNIRAssociacao ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openComparativoNIRAssociacao} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {comparativoNIRCadastroAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/comparativo-nir-associacao"
              selected={pathname === '/comparativo-nir-associacao'}
            >
              <ListItemText primary="Cadastro" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {resumoIndustria && (
        <ListItem
          button
          onClick={() => handleListOpenClick('resumo_industria')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Resumos" />
          {openResumoIndustria ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openResumoIndustria} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {resumoTabelaEntregaIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/resumo-tabela-entrega-industria"
              selected={pathname === '/resumo-tabela-entrega-industria'}
            >
              <ListItemText primary="Tabela de Entrega" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {relatorioViagemIndustria && (
        <ListItem
          button
          onClick={() => handleListOpenClick('relatorio_viagem_industria')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios Viagens" />
          {openRelatorioViagemIndustria ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openRelatorioViagemIndustria} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {relatorioViagemViagemIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-industria-viagem"
              selected={pathname === '/relatorio-industria-viagem'}
            >
              <ListItemText primary="Detalhado" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {relatorioDiarioIndustria && (
        <ListItem
          button
          onClick={() => handleListOpenClick('relatorio_diario_industria')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios Diários" />
          {openRelatorioDiarioIndustria ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openRelatorioDiarioIndustria} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {relatorioDiarioDiarioIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-industria-diario"
              selected={pathname === '/relatorio-industria-diario'}
            >
              <ListItemText primary="Detalhado" />
            </ListItem>
          )}
          {relatorioDiarioGeralIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-industria-diario-geral"
              selected={pathname === '/relatorio-industria-diario-geral'}
            >
              <ListItemText primary="Geral" />
            </ListItem>
          )}
          {relatorioDiarioTipoIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-industria-diario-tipos"
              selected={pathname === '/relatorio-industria-diario-tipos'}
            >
              <ListItemText primary="Por Tipos" />
            </ListItem>
          )}
          {relatorioDiarioEntregaIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-industria-diario-entrega"
              selected={pathname === '/relatorio-industria-diario-entrega'}
            >
              <ListItemText primary="Entrega por Tipo" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {relatorioQuinzenalIndustria && (
        <ListItem
          button
          onClick={() => handleListOpenClick('relatorio_quinzenal_industria')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios Quinzenais" />
          {openRelatorioQuinzenalIndustria ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse
        in={openRelatorioQuinzenalIndustria}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {relatorioQuinzenalQuinzenalIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-industria-quinzenal"
              selected={pathname === '/relatorio-industria-quinzenal'}
            >
              <ListItemText primary="Detalhado" />
            </ListItem>
          )}
          {relatorioQuinzenalFatorQualidadeIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-industria-quinzenal-fq"
              selected={pathname === '/relatorio-industria-quinzenal-fq'}
            >
              <ListItemText primary="Fator Qualidade" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {relatorioSistemaatrIndustria && (
        <ListItem
          button
          onClick={() => handleListOpenClick('relatorio_sistemaatr_industria')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios Sistema ATR" />
          {openRelatorioSistemaatrIndustria ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse
        in={openRelatorioSistemaatrIndustria}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {relatorioQuinzenalSistemaatrIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-industria-quinzenal-sistemaatr"
              selected={
                pathname === '/relatorio-industria-quinzenal-sistemaatr'
              }
            >
              <ListItemText primary="Detalhado" />
            </ListItem>
          )}
          {relatorioQuinzenalSistemaatrQuqIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-industria-quinzenal-sistemaatr-quq"
              selected={
                pathname === '/relatorio-industria-quinzenal-sistemaatr-quq'
              }
            >
              <ListItemText primary="Conferência Quq" />
            </ListItem>
          )}
          {relatorioQuinzenalSistemaatrATRuqIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-industria-quinzenal-sistemaatr-atruq"
              selected={
                pathname === '/relatorio-industria-quinzenal-sistemaatr-atruq'
              }
            >
              <ListItemText primary="Conferência ATRuq" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {relatorioGraficoIndustria && (
        <ListItem
          button
          onClick={() => handleListOpenClick('relatorio_grafico_industria')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Gráficos" />
          {openRelatorioGraficoIndustria ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openRelatorioGraficoIndustria} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {relatorioQuinzenalGraficoIndustria && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-industria-quinzenal-grafico"
              selected={pathname === '/relatorio-industria-quinzenal-grafico'}
            >
              <ListItemText primary="Gráfico" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {relatorioViagemAssociacao && (
        <ListItem
          button
          onClick={() => handleListOpenClick('relatorio_viagem_associacao')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios Viagens" />
          {openRelatorioViagemAssociacao ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openRelatorioViagemAssociacao} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {relatorioViagemViagemAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-associacao-viagem"
              selected={pathname === '/relatorio-associacao-viagem'}
            >
              <ListItemText primary="Detalhado" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {relatorioDiarioAssociacao && (
        <ListItem
          button
          onClick={() => handleListOpenClick('relatorio_diario_associacao')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios Diários" />
          {openRelatorioDiarioAssociacao ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openRelatorioDiarioAssociacao} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {relatorioDiarioDiarioAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-associacao-diario"
              selected={pathname === '/relatorio-associacao-diario'}
            >
              <ListItemText primary="Detalhado" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {relatorioQuinzenalAssociacao && (
        <ListItem
          button
          onClick={() => handleListOpenClick('relatorio_quinzenal_associacao')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios Quinzenais" />
          {openRelatorioQuinzenalAssociacao ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse
        in={openRelatorioQuinzenalAssociacao}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {relatorioQuinzenalQuinzenalAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-associacao-quinzenal"
              selected={pathname === '/relatorio-associacao-quinzenal'}
            >
              <ListItemText primary="Detalhado" />
            </ListItem>
          )}
          {relatorioQuinzenalFatorQualidadeAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-associacao-quinzenal-fq"
              selected={pathname === '/relatorio-associacao-quinzenal-fq'}
            >
              <ListItemText primary="Fator Qualidade" />
            </ListItem>
          )}
          {relatorioQuinzenalGeralAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-associacao-quinzenal-geral"
              selected={pathname === '/relatorio-associacao-quinzenal-geral'}
            >
              <ListItemText primary="Geral" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {relatorioSistemaatrAssociacao && (
        <ListItem
          button
          onClick={() => handleListOpenClick('relatorio_sistemaatr_associacao')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios Sistema ATR" />
          {openRelatorioSistemaatrAssociacao ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse
        in={openRelatorioSistemaatrAssociacao}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {relatorioQuinzenalSistemaatrAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-associacao-quinzenal-sistemaatr"
              selected={
                pathname === '/relatorio-associacao-quinzenal-sistemaatr'
              }
            >
              <ListItemText primary="Detalhado" />
            </ListItem>
          )}
          {relatorioQuinzenalSistemaatrFatorQualidadeAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-associacao-quinzenal-sistemaatr-fq"
              selected={
                pathname === '/relatorio-associacao-quinzenal-sistemaatr-fq'
              }
            >
              <ListItemText primary="Fator Qualidade" />
            </ListItem>
          )}
          {relatorioQuinzenalSistemaatrQuqAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-associacao-quinzenal-sistemaatr-quq"
              selected={
                pathname === '/relatorio-associacao-quinzenal-sistemaatr-quq'
              }
            >
              <ListItemText primary="Conferência Quq" />
            </ListItem>
          )}
          {relatorioQuinzenalSistemaatrATRuqAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-associacao-quinzenal-sistemaatr-atruq"
              selected={
                pathname === '/relatorio-associacao-quinzenal-sistemaatr-atruq'
              }
            >
              <ListItemText primary="Conferência ATRuq" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {relatorioMensalAssociacao && (
        <ListItem
          button
          onClick={() => handleListOpenClick('relatorio_mensal_associacao')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios Mensais" />
          {openRelatorioMensalAssociacao ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openRelatorioMensalAssociacao} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {relatorioMensalRecolhimentoTaxaAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-taxa-associacao"
              selected={pathname === '/relatorio-taxa-associacao'}
            >
              <ListItemText primary="Recolhimento Taxa" />
            </ListItem>
          )}
          {relatorioMensalAssociadosAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-associado-associacao"
              selected={pathname === '/relatorio-associado-associacao'}
            >
              <ListItemText primary="Associados" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {relatorioGraficoAssociacao && (
        <ListItem
          button
          onClick={() => handleListOpenClick('relatorio_grafico_associacao')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Gráficos" />
          {openRelatorioGraficoAssociacao ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse
        in={openRelatorioGraficoAssociacao}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {relatorioQuinzenalGraficoAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-associacao-quinzenal-grafico"
              selected={pathname === '/relatorio-associacao-quinzenal-grafico'}
            >
              <ListItemText primary="Gráfico" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {resumoAssociacao && (
        <ListItem
          button
          onClick={() => handleListOpenClick('resumo_associacao')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Resumos" />
          {openResumoAssociacao ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openResumoAssociacao} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {resumoIndustriaAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/resumo-industria-associacao"
              selected={pathname === '/resumo-industria-associacao'}
            >
              <ListItemText primary="Unidade Industrial" />
            </ListItem>
          )}
          {resumoAcumuladoAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/resumo-acumulado-associacao"
              selected={pathname === '/resumo-acumulado-associacao'}
            >
              <ListItemText primary="Acumulado" />
            </ListItem>
          )}
          {resumoTabelaEntregaAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/resumo-tabela-entrega-associacao"
              selected={pathname === '/resumo-tabela-entrega-associacao'}
            >
              <ListItemText primary="Tabela de Entrega" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {avaliacaoAssociacao && (
        <ListItem
          button
          onClick={() => handleListOpenClick('avaliacao_associacao')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Avaliações" />
          {openAvaliacaoAssociacao ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openAvaliacaoAssociacao} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {avaliacaoAmostragemDiaAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/avaliacao-amostragem-dia-associacao"
              selected={pathname === '/avaliacao-amostragem-dia-associacao'}
            >
              <ListItemText primary="Amostragem por Dia" />
            </ListItem>
          )}
          {avaliacaoAmostragemSafraAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/avaliacao-amostragem-safra-associacao"
              selected={pathname === '/avaliacao-amostragem-safra-associacao'}
            >
              <ListItemText primary="Amostragem por Safra" />
            </ListItem>
          )}
          {avaliacaoClarificanteIndustriaAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/avaliacao-clarificante-industria-associacao"
              selected={
                pathname === '/avaliacao-clarificante-industria-associacao'
              }
            >
              <ListItemText primary="Clarificante Indústria" />
            </ListItem>
          )}
          {avaliacaoAmostragemIntervaloAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/avaliacao-amostragem-intervalo-associacao"
              selected={
                pathname === '/avaliacao-amostragem-intervalo-associacao'
              }
            >
              <ListItemText primary="Amostragem por Intervalo" />
            </ListItem>
          )}
          {avaliacaoAmostragemIntervaloCargaAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/avaliacao-amostragem-intervalo-carga-associacao"
              selected={
                pathname === '/avaliacao-amostragem-intervalo-carga-associacao'
              }
            >
              <ListItemText primary="Amostragem por Carga" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {estratificacaoAssociacao && (
        <ListItem
          button
          onClick={() => handleListOpenClick('estratificacao_associacao')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Estratificações" />
          {openEstratificacaoAssociacao ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openEstratificacaoAssociacao} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {estratificacaoCanaAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/estratificacao-cana-associacao"
              selected={pathname === '/estratificacao-cana-associacao'}
            >
              <ListItemText primary="Cana" />
            </ListItem>
          )}
          {estratificacaoATRAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/estratificacao-atr-associacao"
              selected={pathname === '/estratificacao-atr-associacao'}
            >
              <ListItemText primary="ATR" />
            </ListItem>
          )}
          {estratificacaoFatorQualidadeAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/estratificacao-fq-associacao"
              selected={pathname === '/estratificacao-fq-associacao'}
            >
              <ListItemText primary="Fator Qualidade" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {relatorioProdutorAssociacao && (
        <ListItem
          button
          onClick={() => handleListOpenClick('relatorio_produtor_associacao')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios Produtores" />
          {openRelatorioProdutorAssociacao ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse
        in={openRelatorioProdutorAssociacao}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {relatorioProdutorPagamentoAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/relatorio-associacao-produtor-pagamento"
              selected={pathname === '/relatorio-associacao-produtor-pagamento'}
            >
              <ListItemText primary="Pagamento" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {exportacaoAssociacao && (
        <ListItem
          button
          onClick={() => handleListOpenClick('exportacao_associacao')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Exportação" />
          {openExportacaoAssociacao ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openExportacaoAssociacao} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {exportacaoDiarioAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/exportacao-associacao-diario"
              selected={pathname === '/exportacao-associacao-diario'}
            >
              <ListItemText primary="Dados Diários" />
            </ListItem>
          )}
          {exportacaoResumoDiarioAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/exportacao-associacao-resumo-diario"
              selected={pathname === '/exportacao-associacao-resumo-diario'}
            >
              <ListItemText primary="Dados Diários" />
            </ListItem>
          )}
          {exportacaoQuinzenalAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/exportacao-associacao-quinzenal"
              selected={pathname === '/exportacao-associacao-quinzenal'}
            >
              <ListItemText primary="Dados Quinzenais" />
            </ListItem>
          )}
          {exportacaoMensalAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/exportacao-associacao-mensal"
              selected={pathname === '/exportacao-associacao-mensal'}
            >
              <ListItemText primary="Dados Mensais" />
            </ListItem>
          )}
          {exportacaoFatorQualidadeAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/exportacao-associacao-fq"
              selected={pathname === '/exportacao-associacao-fq'}
            >
              <ListItemText primary="Dados Fator Qualidade" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {safraAssociacao && (
        <ListItem
          button
          onClick={() => handleListOpenClick('safra_associacao')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Safra" />
          {openSafraAssociacao ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openSafraAssociacao} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {safraAtualizacaoIndustriaAssociacao && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/atualizacao-industria-associacao"
              selected={pathname === '/atualizacao-industria-associacao'}
            >
              <ListItemText primary="Atualizações UI" />
            </ListItem>
          )}
        </List>
      </Collapse>

      {safraOrplana && (
        <ListItem button onClick={() => handleListOpenClick('safra_orplana')}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Safra" />
          {openSafraOrplana ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openSafraOrplana} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {safraAtualizacaoIndustriaOrplana && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/atualizacao-industria-orplana"
              selected={pathname === '/atualizacao-industria-orplana'}
            >
              <ListItemText primary="Atualizações UI" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {quinzenalOrplana && (
        <ListItem
          button
          onClick={() => handleListOpenClick('quinzenais_orplana')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios Quinzenais" />
          {openRelatoriosQuinzenaisOrplana ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse
        in={openRelatoriosQuinzenaisOrplana}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {quinzenalAssociacaoRelatq2Orplana && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/orplana-quinzenal-associacao"
              selected={pathname === '/orplana-quinzenal-associacao'}
            >
              <ListItemText primary="Associação (relatq2)" />
            </ListItem>
          )}
          {quinzenalIndustriaRelatq21Orplana && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/orplana-quinzenal-industria-relatq21"
              selected={pathname === '/orplana-quinzenal-industria-relatq21'}
            >
              <ListItemText primary="Unidade Industrial (relatq21)" />
            </ListItem>
          )}
          {quinzenalIndustriaRelatq28Orplana && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/orplana-quinzenal-industria-relatq28"
              selected={pathname === '/orplana-quinzenal-industria-relatq28'}
            >
              <ListItemText primary="Unidade Industrial (relatq28)" />
            </ListItem>
          )}
          {quinzenalEstadoRelatq5Orplana && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/orplana-quinzenal-estado-relatq5"
              selected={pathname === '/orplana-quinzenal-estado-relatq5'}
            >
              <ListItemText primary="Estado (relatq5)" />
            </ListItem>
          )}
          {quinzenalEstadoRelatq50Orplana && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/orplana-quinzenal-estado-relatq50"
              selected={pathname === '/orplana-quinzenal-estado-relatq50'}
            >
              <ListItemText primary="Estado (relatq50)" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {acumuladoOrplana && (
        <ListItem
          button
          onClick={() => handleListOpenClick('acumulados_orplana')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios Acumulados" />
          {openRelatoriosAcumuladosOrplana ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse
        in={openRelatoriosAcumuladosOrplana}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {acumuladoAssociacaoRelata2Orplana && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/orplana-acumulado-associacao-relata2"
              selected={pathname === '/orplana-acumulado-associacao-relata2'}
            >
              <ListItemText primary="Indústria/Associação (relata2)" />
            </ListItem>
          )}
          {acumuladoRegiaoRelata42Orplana && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/orplana-acumulado-regiao-relata42"
              selected={pathname === '/orplana-acumulado-regiao-relata42'}
            >
              <ListItemText primary="Região (relata42)" />
            </ListItem>
          )}
          {acumuladoEstadoRelata46Orplana && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/orplana-acumulado-estado-relata46"
              selected={pathname === '/orplana-acumulado-estado-relata46'}
            >
              <ListItemText primary="Estado (relata46)" />
            </ListItem>
          )}
          {acumuladoEstadoRelata70Orplana && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/orplana-acumulado-estado-relata70"
              selected={pathname === '/orplana-acumulado-estado-relata70'}
            >
              <ListItemText primary="Estado (relata70)" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {estratificacaoOrplana && (
        <ListItem
          button
          onClick={() => handleListOpenClick('estratificacao_orplana')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Estratificações" />
          {openEstratificacoesOrplana ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openEstratificacoesOrplana} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {estratoRelata44Orplana && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/orplana-estrato-cana-relata44"
              selected={pathname === '/orplana-estrato-cana-relata44'}
            >
              <ListItemText primary="Cana (relata44)" />
            </ListItem>
          )}
          {estratoRelata45Orplana && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/orplana-estrato-cana-relata45"
              selected={pathname === '/orplana-estrato-cana-relata45'}
            >
              <ListItemText primary="Cana (relata45)" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {safraRelatorioOrplana && (
        <ListItem
          button
          onClick={() => handleListOpenClick('relatorio_safra_orplana')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios Safra" />
          {openRelatoriosSafraOrplana ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openRelatoriosSafraOrplana} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {safraAtualizacaoOrplana && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/orplana-safra-atualizacao"
              selected={pathname === '/orplana-safra-atualizacao'}
            >
              <ListItemText primary="Atualização" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {safraUnica && (
        <ListItem button onClick={() => handleListOpenClick('safra_unica')}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Safra" />
          {openSafraUnica ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openSafraUnica} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {safraAtualizacaoIndustriaUnica && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/atualizacao-industria-unica"
              selected={pathname === '/atualizacao-industria-unica'}
            >
              <ListItemText primary="Atualizações UI" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {semanalUnica && (
        <ListItem button onClick={() => handleListOpenClick('semanais_unica')}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios Semanais" />
          {openRelatoriosSemanaisUnica ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openRelatoriosSemanaisUnica} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {semanalDetalhadoUnica && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/unica-semanal-detalhado"
              selected={pathname === '/unica-semanal-detalhado'}
            >
              <ListItemText primary="Detalhado" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {quinzenalUnica && (
        <ListItem
          button
          onClick={() => handleListOpenClick('quinzenais_unica')}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios Quinzenais" />
          {openRelatoriosQuinzenaisUnica ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openRelatoriosQuinzenaisUnica} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {quinzenalIndustriaUnica && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/unica-quinzenal-detalhado"
              selected={pathname === '/unica-quinzenal-detalhado'}
            >
              <ListItemText primary="Detalhado" />
            </ListItem>
          )}
          {quinzenalEstadoRelatq5Unica && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/unica-quinzenal-estado-relatq5"
              selected={pathname === '/unica-quinzenal-estado-relatq5'}
            >
              <ListItemText primary="Estado (relatq5)" />
            </ListItem>
          )}
          {quinzenalEstadoRelatq50Unica && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/unica-quinzenal-estado-relatq50"
              selected={pathname === '/unica-quinzenal-estado-relatq50'}
            >
              <ListItemText primary="Estado (relatq50)" />
            </ListItem>
          )}
        </List>
      </Collapse>
      {mensalUnica && (
        <ListItem button onClick={() => handleListOpenClick('mensais_unica')}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios Mensais" />
          {openRelatoriosMensaisUnica ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}
      <Collapse in={openRelatoriosMensaisUnica} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {quinzenalIndustriaUnica && (
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to="/unica-mensal-detalhado"
              selected={pathname === '/unica-mensal-detalhado'}
            >
              <ListItemText primary="Detalhado" />
            </ListItem>
          )}
        </List>
      </Collapse>
      <List>
        <ListItem button onClick={signOut}>
          <ListItemIcon>
            <CadastroIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <DrawerMui
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </DrawerMui>
    </>
  );
};

export default AppDrawer;
